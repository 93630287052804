import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core'
import {
  AccountCircle,
  Close,
  Comment,
  Dashboard,
  DateRange,
  Help,
  People,
  Settings,
} from '@material-ui/icons'
import { NavMenuLink } from '../common'
import {
  headerHeight,
  navColor,
  navIcon,
  navText,
  navigationWidth,
  navigationWidthXs,
} from '../../constants'
import bgImg from '../../images/ET_MenuGraphic.png'
import logo from '../../images/ETLogo_icon.png'

const styles = (theme) => ({
  container: {
    paddingLeft: theme.spacing(),
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.up('lg')]: {
      height: `calc(100% - ${headerHeight})`,

      paddingLeft: 0,

      position: 'relative',
      paddingTop: 15,
    },
  },
  drawer: {
    backgroundImage: `url(${bgImg})`,

    [theme.breakpoints.up('sm')]: {
      width: navigationWidth,
      flexShrink: 0,
      zIndex: 1,
    },
  },
  drawerPaper: {
    backgroundColor: navColor,
    width: navigationWidthXs,

    [theme.breakpoints.up('md')]: {
      top: headerHeight,
      width: navigationWidth,
    },
  },
  helpItem: {
    paddingLeft: 0,
  },
  icon: {
    ...navIcon(theme),
  },
  patternImage: {
    //width: '-webkit-fill-available',
    width: '100%',
  },
  text: {
    ...navText,
  },
  userName: {
    fontSize: 18,
    fontWeight: 300,
    textTransform: 'uppercase',
  },
})

@inject('HeaderStore', 'LoginStore')
@observer
class Navigation extends Component {
  render() {
    const { classes, handleDrawerClose, handleDrawerToggle, mobileOpen } =
      this.props

    const {
      isSuperAdmin,
      isAdmin,
      loggedInUserFirstName,
      loggedInUserLastName,
      siteAdminKey1,
      siteAdminKey2,
    } = this.props.HeaderStore

    const { handleLogout } = this.props.LoginStore

    const drawer = (
      <div className={classes.container}>
        <List>
          <Hidden lgUp>
            <ListItem className={classes.helpItem}>
              <Typography className={classes.userName}>
                {loggedInUserFirstName} {loggedInUserLastName}
              </Typography>
            </ListItem>
          </Hidden>
          <NavMenuLink
            onClick={() => handleDrawerClose()}
            label='Dashboard'
            Icon={<Dashboard />}
            to='/'
          />
          <NavMenuLink
            onClick={() => handleDrawerClose()}
            label='My Teams'
            Icon={<People />}
            to='/myTeams'
          />
          <NavMenuLink
            onClick={() => handleDrawerClose()}
            label='District Goals'
            Icon={<DateRange />}
            to='/districtGoals'
          />
          <NavMenuLink
            onClick={() => handleDrawerClose()}
            label='School Goals'
            Icon={<DateRange />}
            to='/schoolGoals'
          />
          <NavMenuLink
            onClick={() => handleDrawerClose()}
            label='Team Goals'
            Icon={<DateRange />}
            to='/teamGoals'
          />
          <NavMenuLink
            onClick={() => handleDrawerClose()}
            label='Meetings'
            Icon={<Comment />}
            to='/meetings'
          />
          {isAdmin && (
            <NavMenuLink
              onClick={() => handleDrawerClose()}
              label='Admin'
              Icon={<Settings />}
              to='/admin'
            />
          )}
          {isSuperAdmin && (
            <NavMenuLink
              onClick={() => handleDrawerClose()}
              label='Super Admin'
              Icon={<Settings />}
              to='/superAdmin'
            />
          )}
          {isSuperAdmin && (
            <a
              href={`${process.env.REACT_APP_SITE_ADMIN_URL}/${siteAdminKey1}/${siteAdminKey2}`}
              rel='noopener noreferrer'
              style={{ textDecoration: 'none' }}
              target='__blank'>
              <ListItem
                onClick={() => handleDrawerClose()}
                button
                className={classes.helpItem}>
                <ListItemIcon className={classes.icon}>{<Help />}</ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.text }}
                  primary='Site Admin'
                  className={classes.helpItem}
                />
              </ListItem>
            </a>
          )}
          <Hidden lgUp>
            <NavMenuLink
              onClick={() => handleDrawerClose()}
              label='My Account'
              Icon={<AccountCircle />}
              to='/myAccount'
            />
          </Hidden>
          <a
            style={{ textDecoration: 'none' }}
            href='https://enrichingstudents.zendesk.com/hc/en-us'
            rel='noopener noreferrer'
            target='_blank'>
            <ListItem
              onClick={() => handleDrawerClose()}
              button
              className={classes.helpItem}>
              <ListItemIcon className={classes.icon}>{<Help />}</ListItemIcon>
              <ListItemText
                classes={{ primary: classes.text }}
                primary='Help'
                className={classes.helpItem}
              />
            </ListItem>
          </a>
          <Hidden lgUp>
            <ListItem
              onClick={() => handleLogout()}
              button
              className={classes.helpItem}>
              <ListItemIcon className={classes.icon}>{<Close />}</ListItemIcon>
              <ListItemText
                classes={{ primary: classes.text }}
                primary='Logout'
                className={classes.helpItem}
              />
            </ListItem>
          </Hidden>
        </List>
        <img
          alt='Decorative pattern'
          className={classes.patternImage}
          src={bgImg}
        />
      </div>
    )

    return (
      <nav className={classes.drawer}>
        <Hidden smUp implementation='css'>
          <Drawer
            container={this.props.container}
            variant='temporary'
            anchor='left'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}>
            <div className={classes.logoContainer} style={{ height: 81 }}>
              <img alt='Team Agendas Logo' src={logo} style={{ height: 80 }} />
            </div>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant='permanent'
            open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    )
  }
}

export default withStyles(styles)(Navigation)

import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Typography, withStyles } from '@material-ui/core'
import { Loading } from '../common'
import { tertiaryColor } from '../../constants'
import loginPicture from '../../images/team-agendas-hero-image.svg'

import { LoginForm, ForgotPassword } from '../Login'

const styles = (theme) => ({
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  forgotPassword: {
    color: '#fff',
    textDecoration: 'underline',
    cursor: 'pointer',

    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  image: {
    maxWidth: '99%',
    maxHeight: '99%',
    width: '100%',
    height: '100%',
  },
  left: {
    display: 'none',

    //border: '10px solid red',

    [theme.breakpoints.up('md')]: {
      display: 'initial',
      //backgroundImage: `url(${loginPicture})`,
      //backgroundPosition: 'center',
      //backgroundSize: 'cover',
      height: '100%',
      width: '50%',

      left: 0,
    },
  },
  right: {
    //background: `url(${Triangles}) left bottom no-repeat`,
    //backgroundSize: '50%',

    right: 0,
    backgroundColor: tertiaryColor,
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  split: {
    backgroundColor: tertiaryColor,
    height: '100%',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    overflowX: 'hidden',
  },
  textContainer: {
    backgroundColor: 'rgba(255,255,255,.6)',
    bottom: 100,
    left: 0,
    position: 'absolute',

    padding: 15,
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textContent: {
    fontSize: 18,
    textAlign: 'center',
  },
  textHeader: { fontSize: 28, marginBottom: 15, textAlign: 'center' },
})

@inject('LoginStore')
@observer
class Login extends Component {
  render() {
    const { classes } = this.props
    const {
      displayForgotPasswordForm,
      email,
      errorMessage,
      handleForgotPassword,
      handleInputChange,
      handleLogin,
      isLoggingOn,
      isSendingEmail,
      password,
      toggleDisplayForgotPasswordForm,
    } = this.props.LoginStore

    return (
      <div>
        <div className={[classes.split, classes.left].join(' ')}>
          <div
            style={{
              height: '100%',
              //border: '1px solid hotpink',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <img
              src={loginPicture}
              alt='this is a test'
              style={{
                height: '75%',
                width: '100%',
                //border: '1px solid black',
              }}
            />
            <h1
              style={{
                color: '#330066',
                //border: '1px solid black',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}>
              Have more productive meetings, share ideas, and unite your team.
            </h1>
          </div>

          {/* <div className={classes.textContainer}>
            <Typography className={classes.textHeader} variant='h1'>
              Manage PLC processes with one fluid tool, and make your teamwork
              count!
            </Typography>
            <Typography className={classes.textContent}>
              Our PLC management tool helps you make the most of your time, and
              keep track of what students really need.
            </Typography>
          </div> */}
        </div>
        <div className={[classes.split, classes.right].join(' ')}>
          {isLoggingOn ? (
            <Loading login text='Logging in to Team Agendas.' />
          ) : (
            <Fragment>
              {isSendingEmail ? (
                <Loading login text='Sending email with reset instructions' />
              ) : (
                <div className={classes.centered}>
                  {displayForgotPasswordForm ? (
                    <ForgotPassword
                      email={email}
                      handleForgotPassword={handleForgotPassword}
                      handleInputChange={handleInputChange}
                      toggleForgotPassword={toggleDisplayForgotPasswordForm}
                    />
                  ) : (
                    <LoginForm
                      email={email}
                      errorMessage={errorMessage}
                      handleInputChange={handleInputChange}
                      handleLogin={handleLogin}
                      password={password}
                      toggleForgotPassword={toggleDisplayForgotPasswordForm}
                    />
                  )}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Login)

export const navColor = '#EEEEEE'
export const navTextColor = '#959292'

//export const primaryColor = '#EA8124'
export const primaryColor = '#330066'
export const secondaryColor = '#039003'
export const tertiaryColor = '#efd122'

export const subheadingColor = '#E2DFDF'

export const dangerColor = '#C90008'

export const graphColors = [primaryColor, '#095256', '#087F8C', '#5AAA95']
export const avatarColors = [
  '#f44336',
  '#673ab7',
  '#2196f3',
  '#009688',
  '#4caf50',
  '#ffc107',
]
